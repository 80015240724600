import React, { useContext } from 'react';
import Dropdown from 'react-dropdown';
import { withRouter } from 'react-router-dom';
import DashboardContext from '../../context/dashboard/dashboardContext';

const options = [
  'Atlanta',
  'Austin',
  'Baltimore',
  'Boston',
  // "Buffalo",
  'Charleston',
  'Charlotte',
  'Chicago',
  'Cincinnati',
  'Cleveland',
  'Columbus',
  'Dallas',
  'DC Metro',
  'Denver',
  'Detroit',
  'Ft. Lauderdale',
  'Houston',
  'Indianapolis',
  'Jacksonville',
  'Kansas City',
  'Los Angeles',
  'Louisville',
  'Memphis',
  'Miami',
  'Milwaukee',
  'Minneapolis',
  'Nashville',
  'New Orleans',
  'New York',
  'Oahu',
  'Oakland',
  // 'Oklahoma City',
  'Orange County',
  'Orlando',
  'Philadelphia',
  'Phoenix',
  'Pittsburgh',
  'Portland',
  'Raleigh',
  'Richmond',
  'Sacramento',
  'Salt Lake City',
  'San Antonio',
  'San Diego',
  'San Francisco',
  'San Jose',
  'Seattle',
  'St. Louis',
  'Tampa',
  // 'Tucson',
];
const DropdownTitle = ({ marketId, history }) => {
  const dashboardContext = useContext(DashboardContext);
  const { tableData } = dashboardContext;
  const selectedMarketName = tableData.find(m => m.id === marketId).name;
  const onChange = e => {
    const selectedMarketId = tableData.find(m => m.name === e.value).id;
    // console.log(selectedMarketId);
    history.push('/market/' + selectedMarketId);
  };
  const dropdown = (
    <section>
      <Dropdown
        options={options}
        onChange={onChange}
        value={selectedMarketName}
        placeholder="Select an option"
      />
    </section>
  );
  return dropdown;
};

export default withRouter(DropdownTitle);
