import React, { useContext } from 'react';
import DashboardContext from '../../context/dashboard/dashboardContext';

const MarketDetails = ({ marketId }) => {
  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i + 'th';
  };
  const dashboardContext = useContext(DashboardContext);
  const { tableData, segmentationData, marketCategory } = dashboardContext;
  const selectedMarketInfo = tableData.find((e) => e.id === marketId);
  const selectedMarketSegmentationInfo = segmentationData.find(
    (e) => e.id === marketId
  );
  const selectedMarketCategoryInfo = marketCategory.find(
    (e) => e.id === marketId
  );
  const rank = ordinal_suffix_of(selectedMarketInfo['adjusted_rank']);
  const confidence = selectedMarketInfo['confidence_score'];
  const revpar_growth =
    (selectedMarketInfo['adjusted_revpar_growth_rate'] * 100).toFixed(1) + '%';
  const revpar_forecast =
    selectedMarketInfo['adjusted_revpar_forecast'].toFixed(2);
  
  return (
    <div id="market-details" className="wrapper">
      <div id="test">
        <p>
          <strong>Market Details</strong>
        </p>
        <p>2024 - 2028</p>
      </div>
      <div>
        <h1>{rank}</h1>
        <p>Ranking</p>
        <p>out of 47</p>
      </div>
      <div>
        <h1>{revpar_growth}</h1>
        <p>RevPar Growth</p>
        <p>2024-2028</p>
      </div>
      <div>
        <h1>{confidence}</h1>
        <p>Confidence</p>
        <p>scale of 1 to 10</p>
      </div>
      <div>
        <h1>{'$' + revpar_forecast}</h1>
        <p>RevPar</p>
        <p>in 2028</p>
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Market Type &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketSegmentationInfo.seg_type}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Population &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketSegmentationInfo.seg_size}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Geographical &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketSegmentationInfo.seg_geo}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ flex: '0 0 260px' }}>
        <table>
          <tbody>
            <tr>
              <td>
                <p style={{ paddingRight: '20px' }}>
                  <strong>Demand &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketCategoryInfo.Demand}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingRight: '20px' }}>
                  <strong>Trend &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketCategoryInfo.Trend}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingRight: '20px' }}>
                  <strong>NLP &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketCategoryInfo.NLP}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingRight: '20px' }}>
                  <strong>Price &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketCategoryInfo.Price}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingRight: '20px' }}>
                  <strong>SocioEcon &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketCategoryInfo.SocioEcon}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingRight: '20px' }}>
                  <strong>Supply &nbsp;</strong>
                </p>
              </td>
              <td>
                <p>{selectedMarketCategoryInfo.Supply}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default MarketDetails;
